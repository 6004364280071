html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: serif;

  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  /*background: linear-gradient(
    136deg,
    #4484ce,
    #1ad7c0,
    #ff9b11,
    #9b59b6,
    #ff7f7f,
    #ecf0f1
  );*/

  background-size: 1200% 1200%;
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}

.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}

.brand {
  color: #363636 !important;
}

.nav-item {
  transition: color, 0.2s;
}

.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;

  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.socialiconsblack {
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;

  &:hover {
    color: rgb(91 93 94);
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;

  @media (min-width: 360px) {
    min-height: 4rem;
  }

  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 3.375rem;

  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-5px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.skills-tabs {
  flex-grow: 1;
}

.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;

  &>* {
    transform: scaleX(0);
  }
}

.progress-bar-animation {
  @extend .progress;

  &>* {
    animation: progress 1.5s ease-in-out forwards;
  }
}

.cardImage {
  background: transparent;
  border: none;
}

.pxd-4 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important;
}

.icon {
  width: 30px;
}